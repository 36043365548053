import request from '@/utils/request'


// 查询用户积分变动记录列表
export function listLog(query) {
  return request({
    url: '/user/log/list',
    method: 'get',
    params: query
  })
}

// 查询用户积分变动记录分页
export function pageLog(query) {
  return request({
    url: '/user/log/page',
    method: 'get',
    params: query
  })
}

// 查询用户积分变动记录详细
export function getLog(data) {
  return request({
    url: '/user/log/detail',
    method: 'get',
    params: data
  })
}

// 新增用户积分变动记录
export function addLog(data) {
  return request({
    url: '/user/log/add',
    method: 'post',
    data: data
  })
}

// 修改用户积分变动记录
export function updateLog(data) {
  return request({
    url: '/user/log/edit',
    method: 'post',
    data: data
  })
}

// 删除用户积分变动记录
export function delLog(data) {
  return request({
    url: '/user/log/delete',
    method: 'post',
    data: data
  })
}
