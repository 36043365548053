<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="$t('用户积分变动记录.订单id')" prop="orderId" >
        <a-input v-model="form.orderId" :placeholder="$t('通用.输入.请输入')+$t('用户积分变动记录.订单id')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('用户积分变动记录.用户ID，外键{user.id}')" prop="userId" >
        <a-input v-model="form.userId" :placeholder="$t('通用.输入.请输入')+$t('用户积分变动记录.用户ID，外键{user.id}')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('用户积分变动记录.类型,目前只有订单,1')" prop="type" >
      </a-form-model-item>
      <a-form-model-item :label="$t('用户积分变动记录.变动的积分数')" prop="score" >
        <a-input v-model="form.score" :placeholder="$t('通用.输入.请输入')+$t('用户积分变动记录.变动的积分数')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('用户积分变动记录.变动前剩余积分')" prop="beforeScore" >
        <a-input v-model="form.beforeScore" :placeholder="$t('通用.输入.请输入')+$t('用户积分变动记录.变动前剩余积分')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('用户积分变动记录.变动后剩余积分')" prop="afterScore" >
        <a-input v-model="form.afterScore" :placeholder="$t('通用.输入.请输入')+$t('用户积分变动记录.变动后剩余积分')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('用户积分变动记录.积分变动备注')" prop="descr" >
        <a-input v-model="form.descr" :placeholder="$t('通用.输入.请输入')+$t('用户积分变动记录.积分变动备注')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('用户积分变动记录.备注')" prop="remark" >
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLog, addLog, updateLog } from '@/api/user/log'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        orderId: null,

        userId: null,

        type: null,

        score: null,

        beforeScore: null,

        afterScore: null,

        descr: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: this.$t('用户积分变动记录.用户ID，外键{user.id}')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        type: [
          { required: true, message: this.$t('用户积分变动记录.类型,目前只有订单,1')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        score: [
          { required: true, message: this.$t('用户积分变动记录.变动的积分数')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        beforeScore: [
          { required: true, message: this.$t('用户积分变动记录.变动前剩余积分')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        afterScore: [
          { required: true, message: this.$t('用户积分变动记录.变动后剩余积分')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        descr: [
          { required: true, message: this.$t('用户积分变动记录.积分变动备注')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        orderId: null,
        userId: null,
        type: null,
        score: null,
        beforeScore: null,
        afterScore: null,
        descr: null,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLog({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateLog(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addLog(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
